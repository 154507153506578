// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-for-judges-js": () => import("./../../../src/pages/for-judges.js" /* webpackChunkName: "component---src-pages-for-judges-js" */),
  "component---src-pages-for-lawyers-js": () => import("./../../../src/pages/for-lawyers.js" /* webpackChunkName: "component---src-pages-for-lawyers-js" */),
  "component---src-pages-get-help-js": () => import("./../../../src/pages/get-help.js" /* webpackChunkName: "component---src-pages-get-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news-&-events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-research-team-js": () => import("./../../../src/pages/research-team.js" /* webpackChunkName: "component---src-pages-research-team-js" */),
  "component---src-pages-the-project-js": () => import("./../../../src/pages/the-project.js" /* webpackChunkName: "component---src-pages-the-project-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

